<template>
  <PullRefresh :on-refresh="blockScroll ? blankRefresh : onRefresh">
    <v-container class="schedule-container">
      <v-row no-gutters class="row-base-information mt-2">
        <v-col @click.prevent="$refs.calendarComponent.toggleBaseContainer(true)" cols="auto" class="align-self-center">
          <v-icon small color="white" class="align-middle">
            mdi-information-variant-circle-outline
          </v-icon>
          <span class="font-16 fw-500 white--text align-middle ml-1">
            Base Informations
          </span>
        </v-col>
      </v-row>
      <v-card class="schedule-card">
        <CalendarSchedule ref="calendarComponent" :attributes="Attributes" :loading="loadingSchedule" @block-scroll="isBlockScroll" @update-page="updatedPage" @dayclick="dayclick" />
      </v-card>
    </v-container>
  </PullRefresh>
</template>

<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'Schedule',
  data: vm => ({
    loadingSchedule: false,
    loadingData: false,
    showDaysPrev: 6,
    showDaysNext: 6,
    dayActive: null,
    dialogSchedule: false,
    logbookDayList: [],
    selectedDate: {},
    blockScroll: false
  }),
  computed: {
    Attributes () {
      const newAttribute = []
      this.$store.state.pilot_schedule.data.forEach((res) => {
        const attr = {
          customData: res,
          dates: new Date(this.$localDT(res.duty_date, 'datedefault'))
        }
        newAttribute.push(attr)
      })
      return newAttribute
    },
    AbleToOpen () {
      let response = false
      let lastLegETA = 0
      const schedules = this.$store.state.schedules.data
      for (let s = 0; s < schedules.length; s++) {
        const ss = schedules[s]
        const etaDate = new Date(ss.duty_local_date)
        const etaSplitted = ss.eta_local_time ? ss.eta_local_time.split(':') : null
        if (!etaSplitted) {
          response = false
        } else {
          etaDate.setHours(etaSplitted[0])
          etaDate.setMinutes(etaSplitted[1])
          if (etaDate > lastLegETA) {
            lastLegETA = etaDate
          }
        }
      }

      const allowedTime = this.$addMinutes(new Date(lastLegETA), 10) // add 10 minutes after last eta
      if (lastLegETA && (new Date() > allowedTime)) {
        response = true
      }
      return response
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (to.name === 'Schedule' && !from) {
          if (to.query.scheduleDate) {
            this.$router.replace('/')
          }
        }
        this.dialogSchedule = false
      }
    }
  },
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.onRefresh()
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })
  },
  methods: {
    blankRefresh () {
      return false
    },
    onRefresh () {
      const refreshData = () => {
        this.updatedPage(this.selectedDate)
        if (localStorage.getItem('t') && localStorage.getItem('push_token') && !this.$store.state.admin) {
          this.$store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
            if (!res) {
              this.$toastNotif({
                message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
                type: 'info',
                timer: 3500
              })
              this.$store.dispatch('DO_LOGOUT')
            }
          })
        }
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          refreshData()
          resolve()
        }, 1000)
      })
    },
    isBlockScroll (value) {
      this.blockScroll = value
    },
    dayclick (v) {
      this.dayActive = v
      this.loadSchedules({
        from: v.id,
        to: v.id
      })
      this.loadLogbook({
        from: v.id,
        to: v.id
      })
      setTimeout(() => {
        this.dialogSchedule = true
      }, 100)
    },
    loadLogbook (fromTo) {
      this.$store.dispatch('LOGBOOK_GET', 'noreturn=&from=' + (fromTo.from || '') + '&to=' + (fromTo.to || ''))
        .then((res) => {
          if (res.status) {
            this.logbookDayList = res.data.data.data || []
          }
        })
    },
    loadSchedules (toFrom) {
      this.loadingData = true
      const currDate = this.$TodayDate()
      this.$store.dispatch('PILOT_SCHEDULES_GET', { q: '?sort=ASC&from=' + (toFrom ? toFrom.from : this.$DateDiffDays(currDate, this.showDaysPrev)) + '&to=' + (toFrom ? toFrom.to : this.$DateAddDays(currDate, this.showDaysNext)) }).then((res) => {
        if (res.status) {
          // this.afl.schedules = res.data.data.data || []
          // this.$router.push('?scheduleDate=' + toFrom.to)
        }
        this.loadingData = false
      })
    },
    updatedPage (toFrom) {
      this.$store.dispatch('ME_GET')
      this.selectedDate = toFrom
      this.$store.commit('SCHEDULES_SET', { total: 0, data: [] })
      this.loadPilotSchedules(toFrom)
    },
    loadPilotSchedules (toFrom) {
      this.loadingSchedule = true
      if (toFrom) {
        this.$store.dispatch('PILOT_SCHEDULE_GET', { id: this.$store.state.me.id, q: '?from=' + toFrom.from + '&to=' + toFrom.to }).then((r) => {
          this.loadingSchedule = false
        })
      } else {
        this.$store.dispatch('PILOT_SCHEDULE_GET', { id: this.$store.state.me.id, q: '' }).then((r) => {
          this.loadingSchedule = false
        })
      }
    },
    differentDate (index, dateDeparture) {
      if (index === 0) {
        return true
      }
      dateDeparture = this.$localDT(dateDeparture, 'date')
      const dateBefore = this.$localDT(this.$store.state.schedules.data[index - 1].duty_local_date, 'date')
      if (dateBefore === dateDeparture) {
        return false
      } else {
        return true
      }
    },
    ExportToLogbook (id) {
      this.dialogSchedule = false
      const findScheduleInDate = this.$store.state.schedules.data.filter(r => parseInt(r.id) === parseInt(id))
      this.$store.commit('EXPORT_SCHEDULE', {
        data: findScheduleInDate
      })
      setTimeout(() => {
        this.$router.push('/schedule?dialogLogbook=0')
      }, 300)
    },
    ExportToLogbookBulk (dt) {
      const findScheduleInDate = this.$store.state.schedules.data.filter(r => this.$localDT(r.duty_local_date, 'date') === this.$localDT(dt, 'date'))
      this.$store.commit('EXPORT_SCHEDULE', {
        data: findScheduleInDate
      })
      setTimeout(() => {
        this.$router.push('/schedule?dialogLogbook=0')
        this.dialogSchedule = false
      }, 300)
    },
    GetFleet (id) {
      const fleet = this.$store.state.logbook.aircrafts.find(r => r.id === id)
      if (fleet) {
        return fleet.fleet_reg
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/global-vars';

@mixin flight-type {
  &.regular {
      background-color: #157402;
  }
  &.perintis {
      background-color: #b66202;
  }
  &.charter {
      background-color: #1c39be;
  }
  &.positioning {
      background-color: #8605b9;
  }
  &.ferry {
      background-color: #05b9b0;
  }
  &.medevac {
      background-color: #776b03;
  }
  &.corporate {
      background-color: #576fbe;
  }
}
@mixin flight-type-line {
  &.regular {
    border-color: #157402!important;
    color: #157402!important;
  }
  &.perintis {
    border-color: #b66202!important;
    color: #b66202!important;
  }
  &.charter {
    border-color: #1c39be!important;
    color: #1c39be!important;
  }
  &.positioning {
    border-color: #8605b9!important;
    color: #8605b9!important;
  }
  &.ferry {
    border-color: #05b9b0!important;
    color: #05b9b0!important;
  }
  &.medevac {
    border-color: #776b03!important;
    color: #776b03!important;
  }
  &.corporate {
    border-color: #576fbe!important;
    color: #576fbe!important;
  }
}
.schedule-container{
  background-color: $primary;
  box-shadow: none !important;
  min-height: calc(100vh - 70px);
  padding: 45px 0 0;
  overflow: hidden auto;
  .row-base-information{
    padding: 0 30px;
  }
  .schedule-card{
    background-color: $white;
    border-radius: 50px 50px 0 0;
    height: 100%;
    min-height: calc(100vh - 125px);
    margin-top: 16px;
    padding-bottom: 30px;
  }
}

.schedule-item {
  .flight-type {
    color: #fff;
    text-transform: uppercase;
    line-height: 100%;
    border: solid 1px;
    background: #fff!important;
    @include flight-type-line();
  }
  >.dep-arr {
    >span {
      display: inline-block;
    }
  }
}
.calendar-area {
  position: relative;
  max-width: 100%;
  overflow-x: auto;
}
</style>
